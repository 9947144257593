import React from 'react';
import { graphql } from 'gatsby';
import mq from '@/styles/mq';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';
import GalerieContainer from '@/containers/Galerie/GalerieContainer';

import { PrismicGaleriePage } from '@/domain/PrismicGaleriePage';
import { PageContext } from '@/domain/PageContext';
interface GalerieTemplateProps {
  data: {
    prismicGaleriePage: PrismicGaleriePage;
  };
  pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicGaleriePage(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        galerie_content {
          raw
        }
        gallery {
          gallery_image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  }
`;

const GalerieTemplate = ({
  data,
  pageContext,
}: GalerieTemplateProps): JSX.Element => {
  const { alternate } = pageContext;
  const { lang, uid, alternate_languages } = data?.prismicGaleriePage;
  const { meta_title, meta_description } = data?.prismicGaleriePage?.data;

  const selectlang = {
    lang,
    uid: uid,
    alternate_languages,
  };
  const seo = {
    title: meta_title,
    description: meta_description,
    lang: lang,
    url: `${lang}`,
  };
  const linkAlternate = alternate?.map((data) => ({
    lang: data?.lang,
    url: `${data?.lang}/${data?.uid}`,
  }));
  return (
    <Layout selectLang={selectlang}>
      <SEO
        lang={seo.lang}
        title={`${seo.title}`}
        url={seo.url}
        description={seo.description}
        alternate={linkAlternate}
      />
      <GalerieContainer data={data} lang={lang} />
    </Layout>
  );
};

export default GalerieTemplate;
