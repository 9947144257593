import React, { useState } from 'react';
import styled from '@emotion/styled';
import Lightbox from 'react-image-lightbox';
import { Img } from '@/components/atoms';

import ImageDataType from '@/types/data/image';
import mq from '@/styles/mq';

import 'react-image-lightbox/style.css';

interface GalerieProps {
    gallery: {
        image: ImageDataType;
    }[];
}

const GalerieRoot = styled.section`
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;

const GalerieContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    ${mq('xs')} {
        justify-content: flex-start;
    }
`;
const ImageContainer = styled.div``;
const Image = styled(Img)`
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;    
    border-radius: 5px;
    width: 100%;
    height: 250px;
    cursor: pointer;
    margin-right: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    ${mq('xxs')} {
        width: 340px;
        height: 240px;
        margin-right: ${({ theme }) => theme.spacing(3)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('xs')} {
        width: 230px;
        height: 130px;
        margin-right: ${({ theme }) => theme.spacing(3)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('sm')} {
        width: 300px;
        height: 200px;
        margin-right: ${({ theme }) => theme.spacing(3)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        width: 300px;
        height: 200px;
        margin-right: ${({ theme }) => theme.spacing(3)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('xl')} {
        width: 350px;
        height: 250px;
        margin-right: ${({ theme }) => theme.spacing(5)};
        margin-bottom: ${({ theme }) => theme.spacing(5)};
    }
`;


const Galerie = ({ gallery }: GalerieProps): JSX.Element => {
    const [index, setIndex] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <GalerieRoot>
            <GalerieContainer>
                {gallery?.map((image, i) => (
                    <ImageContainer onClick={() => {
                        setIndex(i);
                        setOpen(true);
                    }}>
                        <Image image={image?.image} />
                    </ImageContainer>
                ))}
            </GalerieContainer>
            {open && (
                <Lightbox
                    mainSrc={gallery?.[index]?.image.gatsbyImageData?.images?.fallback?.src || ``}
                    nextSrc={gallery?.[(index + 1) % gallery.length]?.image?.gatsbyImageData?.images?.fallback?.src}
                    prevSrc={gallery?.[(index + gallery.length - 1) % gallery.length]?.image?.gatsbyImageData?.images?.fallback?.src}
                    onCloseRequest={() => { setOpen(false) }}
                    enableZoom={true}
                    imagePadding={70}
                    onMovePrevRequest={() =>
                        setIndex(
                            (index + (gallery?.length || 0) - 1) % (gallery?.length || 0),
                        )
                    }
                    onMoveNextRequest={() =>
                        setIndex((index + 1) % (gallery?.length || 0))
                    }
                />
            )}
        </GalerieRoot>
    );
};

export default Galerie;