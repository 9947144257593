import React from 'react';
import styled from '@emotion/styled';

import { Banner, Content, ContactBox } from '@/components/molecules';
import Galerie from '@/containers/Galerie/Galerie';
import { PrismicGaleriePage } from '@/domain/PrismicGaleriePage';

interface GalerieContainerProps {
    data: {
        prismicGaleriePage: PrismicGaleriePage;
    };
    lang: string;
}

const GalerieContainerRoot = styled.section``;

const GalerieContainer = ({ data, lang }: GalerieContainerProps): JSX.Element => {
    const { galerie_content, gallery } = data?.prismicGaleriePage?.data;

    const ContentData = {
        content: galerie_content?.raw
    }

    const GalleryData = {
        gallery: gallery?.map(img => ({
            image: img?.gallery_image
        }))
    }

    return (
        <GalerieContainerRoot>
            <Banner lang={lang} />
            <Content {...ContentData} />
            <Galerie {...GalleryData} />
            <ContactBox lang={lang} />
        </GalerieContainerRoot>
    );
};

export default GalerieContainer;